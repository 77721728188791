$fontsFolder: "/assets/fonts";
$openSansHebFolder: "#{$fontsFolder}/open-sans-hebrew";
$openSansFonts: "Bold","BoldItalic","ExtraBold","ExtraBoldItalic","Italic","Light","LightItalic","Regular";

@each $font in $openSansFonts{
	@font-face {
		font-family: 'OpenSansHebrew-#{$font}';
		font-style: normal;
		font-weight: normal;
		src: url('#{$openSansHebFolder}/OpenSansHebrew-#{$font}.ttf');
	}
}

@font-face {
  font-family: FbExtazaRegular;
  src: url('#{$fontsFolder}/FbExtazaRegular.ttf');
}
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
