/* Mobile  */
section.pad{
  @include genPad(5);
} 
button{

	&.btn{
		background-color: #59a078;
		color:$linkColor;
		border:0;
		padding:4px 12px;
		border-color: #59a078;
		text-align: center;
		vertical-align: middle;
		border-radius: .25rem;
		
		&:disabled{
			opacity: .65;
		}
	}

	&.link{
		@extend %btnLink;
	
		&.empty{
			background: transparent;
			color: $color;
			border: 2px solid $color;
		}
	
		&.checked{
			background-color: $checkedColor;
			background-image: url(#{$images}/check.png);
			background-repeat: no-repeat;
			background-size: 10px;
			background-position: 94% 60%;
		}
	
		&:hover:not([disabled]){
			box-shadow: 0px 2px 5px #888;
			color: #000;
		}
		&[disabled]{
			opacity: 0.6;
		}
	}
	&.gen-rnd{
		@extend %genBtnCls;
	}
}
.only-lg{
	display:none;
}
.non-xs{
	display: none;
}
.only-app{
	display:none;
}


/* Header */

header {
	background-color: $darkerBlue;
	padding: 5px 10px;
	flex-wrap: wrap;
	height:$headerHxs;
	@extend %flexAlignCenter;
	justify-content: start;
	color:$linkColor;

	.start-side{
		@include rtl(margin-right,margin-left,auto);
		order:1;
		@extend %flexAlignCenter;

		&>div{
			margin:auto 8px;
		}
	}
	.logo{
		max-width:80px;
		cursor: pointer;
	}
	.menu{
		// margin-left:-8px;

		&.user-links{
			span,a{
				font-size:0.75rem;
			}
		}

		button{
			border:0;
			padding:8px;
			background: transparent;
			@extend %flexAlignCenter;
			justify-content: space-evenly;
			color:$linkColor;

			.langIcon{
				margin:auto 4px;
			}
		}
		&.lang{
			button{
				padding:0;
			}
			.dropdown{
				left:0;
			}
		} 

		button>span{
			margin:auto 12px;
		}
		&.children{
			order:2;

			button{
				border:1px white solid;
				border-radius: 4px;
			}
		}
		&>ul>li{
			margin:auto 8px;
			img{
				margin:auto 8px;
			}
			span::after,a::after{
				content: "";
				position: absolute;
				height: 0.5rem;
				width: 1px;
				background: $linkColor;
				margin: 3px 8px;
			}
		}
		& li:first-child span::after,& li:last-child span::after{
			display: none;
		}
	}
	.start-side .fa-bars{
		font-size:25px;
		color:$linkColor;
		cursor: pointer;
	}

	&>div{
		order:3;
	}
}

.dropdown{
	top:$headerHxs;
	position:absolute;
	background: $blueBg;
	padding:10px 15px;
	z-index: 99;
	border-radius: 0 0 10px 10px;
	box-shadow: black 0 2px 8px;

	ul{
		flex-direction: column;
		align-items: flex-start;

		li{
			padding:8px 0;
			width:100%;
			border-bottom: 1px solid #8e8e8e;

			a{
				width:100%;
			}

			span.marg-bef{
				@include rtl(margin-left,margin-right,10px);
			}

			&:last-child{
				border-bottom:0;
			}
		}
	}
}

/* Home */
.top-notif{
	background: $darkRed;
	color:$linkColor;
	text-align: center;
	padding: 5px 0;
	height:$topNotifH;

	p{
		margin:0;
	}
}


$animationLength:30*1000*1000*1000;
$animationProp:30;
@function getAnimBgPos(){
	@return $animationLength*$animationProp;
}
@keyframes homeBg {
  from {background-position-x: 0px;}
  to {background-position-x: #{getAnimBgPos()}px;}
}
.home-bg{
	background-image: url(#{$images}/body-bg.jpg);
	background-position: top;
	background-repeat: repeat;
	width: 100vw;
	background-size: auto calc(100vh - #{$headerHxs} - #{$topNotifH});
	height: calc(100vh - #{$headerHxs} - #{$topNotifH});

	&.doAnimate{
		animation: #{$animationLength}s infinite linear homeBg;
	}
	
	.overlay{
		background-color:rgba(0,0,0,0.6);
		height:100%;
	}
}

.home{
	.content{
		@extend %flexAlignCenter;
		flex-direction: column;
		padding:40px 0 0;

		img.home-logo{
			max-width: 120px;
		}

		h2{
			text-align: center;
			font-weight: bold;
			color:white;
			text-shadow: 0px 0px 12px #000;
			font-size: 24px;
			margin-top: 40px;
			margin-bottom:40px;
		}
		.btns{
			display:flex;
			flex-direction: column;

			button{
				@extend %btnMargin;
				@extend %genBtnCls;

				&.red{
					color: $linkColor;
					background:$darkRed;
				}
				&.white{
					color: black;
					background:white;
				}
			
			}
		}
		img.keyboard{
			max-width: 80%;
		}
	}
	img.sitting-jumbee{
		display:none;
	}
}

.langIcon {
  width: 16px;
  height: 11px;
  background-image: url(#{$images}/flags/en.png);
  background-repeat: no-repeat;
  display: inline-block;
  margin: 0px;
	background-position: center center;
	
	// @each $lang in $langs{
	// 	&:lang(#{$lang}) {
	// 		// background-image: url(#{$images}/flags/#{$lang}.png);
	// 	}
	// }
}

/* static */
.static{
	h1{
		font-size: 25px;
		text-align: center;
		color:#515151;
		margin:40px auto 20px;
	}
	.content{
		@extend %contentBox;
	}
}

/* filter */
.bg-blue{
	height: calc(100vh - #{$headerHxs});
	background: $blueBg;
	color: white;
	@extend %flexAllCenter;
	flex-direction: column;
}
.filter .bg-blue{
	p{
		font-size: 25px;
		font-weight: bold;
		margin-bottom:1rem;
	}
	ul{
		flex-direction: column;
		
		li{
			padding:15px 40px;
			border-bottom: 1px solid #ccc;
			cursor: pointer;

			&.active,&:hover{
				font-weight: bold;
				background-image: url(#{$images}/check.png);
				background-repeat: no-repeat;
				background-position: center right;
				background-size: 16px;
			}
		}
	}
}
.close{
	@extend %bgCenter;
	background-image: url(#{$images}/cross.png);
	background-size: 20px;
	@include wh(40);
	@extend %circ;
	background-color: white;
	position: absolute;
	top: 140px;
  left: 10%;
  cursor: pointer;
}

/* modal-form */
.modal-form{
	.bg-blue{
		justify-content: start;
		padding-top:60px;
	}
	.close{
		// margin-bottom:-10px;
		margin-top:-50px;
		background-size: 20px;
		@include wh(40);
		position: absolute;
		top:inherit !important;
		bottom: inherit !important;
		right: inherit !important;
		left: inherit !important;
	}
	.modal{
		color:$color;
		background: #fff;
		box-shadow: 0px 0px 20px #373737;
		border-radius: 10px;
		margin: 0 auto 70px;
		width:90%;

		.header{
			display: flex;

			&>li{
				@extend %flexAllCenter;
				cursor: pointer;
				flex-grow: 1;
				color:#8b8b8b;
				background-color: #e8e8e8;
				padding:10px;

				&.active{
					color:#0b336f;
					font-weight: bold;
					background-color: white;;
				}
				&:first-child{
					@include rtl(border-top-left-radius,border-top-right-radius,10px);
				}
				&:last-child{
					@include rtl(border-top-right-radius,border-top-left-radius,10px);
				}
			}
		}
		.content{
			padding:20px 10px;
		}
	}
	.form-webee-img{
		left: calc(30% - 163px - 10px);
		position: absolute;
		max-width:100%;
	}
} 

ul.form{
  align-items: flex-start;
	flex-direction: column;
	
	label{
		margin:0 10px;
	}
	li{
		display: block;
		width:100%;
		margin-bottom: 10px;

		p{
			margin-top:8px;
			font-size:0.7rem;
		}
		&.kid-row{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			select{
				min-width:60px;
			}

			&>div{	
				margin:0 4px;
				// width:30%;
				&:first-child{
					width:100%;
					margin-bottom:6px;
				}
			}
		}
	}
	button.add-kid{
		// margin-bottom:20px;
	}
}
.regi-btns{ 
	margin-top:20px;
	display: flex;
	flex-direction: column;
	&>div{
		display: flex;
		justify-content: space-between;
		button{
			width:49% !important;
	
			&:only-child{
				width:100% !important;
			}
		}
	}
	
}
p.error{
  color:red;
  font-size:14px;
	margin-top:-14px;
	
	&.btn-error{
		margin-top:0;
		margin-bottom:1px;
	}
}


input.form-control,select.form-control,.react-datepicker__input-container input {
	font-size:15px;
	background-color: #f0f0f0;
	width: 100%;
  padding: .375rem .75rem;
	/* line-height: 1.5; */
	color: #495057;
	border: 1px solid #ced4da;
	border-radius: .25rem;

	&:focus{
		background-color: white;
		border:1px solid $darkerBlue;
		outline:none;
	}
}

.react-datepicker__input-container input{
	width:120px;
}


/* store */
.store{
	.top-area{
		padding:20px 0;
		text-align: center;
	}
	.top-area button{
		background: $titleRed;
	}
	&.pad{
		@include genPad(1);
		padding-bottom:60px;
	}
} 

.product{
	margin:0 auto 10px;
	width:100%;
	background-color: white;
	display: flex;
	border-radius: 20px;
	box-shadow: 0px 0px 30px #d9d9d9;
	
	img{
		max-width: 30%;
		max-height:90px;
		/* height:100%; */
		@include rtl(border-top-left-radius,border-top-right-radius,20px);
		@include rtl(border-bottom-left-radius,border-bottom-right-radius,20px);
	}

	.info{
		width:100%;	
		// padding:20px 25px;
		padding:8px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		p{
			font-size: 10px;
		}
		h5{
			font-weight: bold;
			color: #515151;
			font-size: 12px;
		}
		

		.btns{
			@extend %flexAlignCenter;
			// justify-content: space-between;
			// flex-direction: column;

			a:first-child,button:first-child{
				@include rtl(margin-right,margin-left,auto,0);
			}

			.old-price{
				text-decoration: line-through;
				font-size:12px;
				margin:0 8px;
			}



			button{
				height:22px;
				font-size: 10px;
				padding:3px 18px;
    		margin: 0;
				// margin:4px 0;
				&.empty{
					padding:3px 10px;
				}	
			}
		}
	}
}
.ages{
	font-size: 8px;
	margin-top: 10px;
		
	position: absolute;
	// margin-top: 30px;
	background-color: white;
	padding: 2px 6px;
	border: 1px solid transparent;
	border-right: 0;
	transition: 0.5s;
	font-weight: bold;
	@include rtl(border-top-right-radius,border-top-left-radius,20px);
	@include rtl(border-bottom-right-radius,border-bottom-left-radius,20px);
	color: $darkerBlue;

	&:hover{
		padding: 5px 20px;
		border: 1px solid $blueBg;
		border-right: 0;
		color:black;
	}
}

.filter-icon{
	@extend %bgCenter;
	color: $color;
	@include wh(60);
	background-color: #fff;
	position: fixed;
	right: 5%;
	bottom: 26px;
	box-shadow: 0px 2px 10px #393939;
	@extend %circ;
	background-image: url(#{$images}/filter2.png);
	background-size: 25px;
	cursor: pointer;
	z-index: 2;
}
.footer{
	background: $titleRed;
	color: $linkColor;
	@extend %flexAllCenter;
	padding: 12px 0;
	position: fixed;
	width: 100%;
	left: 0;
	bottom: 0;
	z-index: 1;

	p{
		font-size:20px;
		font-weight: bold;
		margin:0 10px;
		cursor: pointer;
	}
	.cart{
		@extend %bgCenter;
		position: relative;
		margin: 0px 10px;
		background-image: url(#{$images}/cart.png);
		background-size: 100%;
		@include wh(40);
		background-color: transparent;
		cursor: pointer;

		&.payment{
			background-image: url(#{$images}/secure-payment.png);
		}

		.cart-number{
			position: absolute;
			@include wh(25);
			@extend %circ;
			background: #fff;
			color: $titleRed;
			text-align: center;
			font-size: 14px;
			@extend %flexAllCenter;
			top: -10px;
			left: -17px;
			border: 3px solid $titleRed;
		}
	}
}


/* cart */
.cart{
	&.pad{
		padding-bottom:140px;
	}

	.top-wrap >p{
		margin:10px 66px 40px;
	}
	.product.info h2{
		color: $titleRed;
		font-size: 28px;
		font-weight: bold;
	}
	.info{
		// flex-direction: row;
		// align-items: center;

		.btns{
			justify-content: space-between;
			align-items: center;

			.cart-price{
				font-weight:bold;
				font-size:$fontSize;
			}
		}
	}
	.footer{
		.sum{
			margin:0 4px;
		}
	}
}
 
.back-btn{
	color: #fff;
	background-color: $darkerBlue;
	@include wh(50);
	display: inline-block;
	text-align: center;
	@extend %circ;
	padding: 11px 0px;
	font-size: 20px;
	@include rtl(margin-right,margin-left,15px);
	font-weight: normal;
	background-image: url(#{$images}/arrow-right.png);
	background-size: 20px;
	@extend %bgCenter;
	cursor: pointer;
	@include rtlSameAttr(transform,scaleX(-1),scaleX(1));
}

.above-footer{
  position: fixed;
  background: white;
  width: 100%;
  display: flex;
  justify-content: center;
  bottom: 64px;
  left: 0;
  padding: 20px;
	box-shadow: 0px 2px 5px #888;
	
	p{
		margin:0;
	}
}

/* product-show */
.top-area-with-btn{
	display: flex;
	align-items: flex-start;
	
	.texts{
		padding-top:5px;
		flex-grow:1;
		max-width: 80%;

		&>.stars-cont{
			display:none;
		}
	}
	h4{
		margin:0;
		font-weight: bold;
		color:$color;
	}
}
.product-show{
	&.pad{
		@include genPad(2);
	}
	
	
	.stars-cont{
		display: flex;

		span{
			margin:0 4px;
		}
		img{
			width:20px;
		}
		ul{
			flex-direction: column;
			width:100%;
			align-items: flex-start;
			
			li{
				margin:4px 0;
			}
		}
	}
	.game-details{
		display: flex;
		flex-direction: column;	
		padding-bottom:40px;

		&>.stars-cont{
			margin-top:20px;
			padding:0 15px;
			img{
				width:10px;
			}
			span{
				font-size: 10px;;
			}
		}
		.top-wrap{
			&>p{
				margin:10px 66px 40px;
			}
			.top-area-with-btn{
				.texts{
					max-width: 80%;
				}
				.back-btn{
					@include wh(30);
				}
				h4{
					font-size: $fontSize;
					margin-bottom: 8px;;
				}
				p{
					font-size:14px;
					min-width:80vw;
				}
			}
		}
		.product-pic{
			margin-top:0;
			img{
				border-radius: 20px;
				min-width:100%;
				max-width:100%;
			}
		}
	}
	
	.product-pic{
		margin-top:20px;
		flex-grow: 1;
		.ages{
			font-size:12px;
			margin-top:20px;
		}

		button{
			margin-top:20px;
			width:100%;
		}
	}
	.more-games{
		border-top: 1px #b7b7b7 solid;
		padding-top:20px;

		h3{
			text-align: center;
			color:#515151;
			margin-bottom: 20px;
			font-size:14px;
		}
	}
}

/* charts */
.admin{
	.main>div{
		margin:0 auto;
	}
}
.charts{
	&.pad{
		@include genPad(2);
	}

	.btns{
		button{
			font-size:14px;

			&.filter{
				@extend %bgCenter;
				@include rtl(margin-left,margin-right,auto);
				@include wh(45);
				display: flex;
				@extend %circ;
				background-image: url(#{$images}/filter.png);
				background-size: 18px;
				background-color: $titleRed;
			}
		}
		
		
	} 
	.main {
		padding: 0;
		&>p{
			font-size:14px;
			padding:0 20px;
		}
		.stats{
			margin-top:20px;
			width:100%;
			display: flex;

			&>div{
				width:50%;
				flex-direction: column;
				@extend %flexAlignCenter;
			}	
			p{
				color:$color;
				text-align: center;
			}
			.results{
				@include rtl(border-right,border-left,1px #e6e6e6 solid);
			}
			h4{
				color:$darkerBlue;
				font-size: 14px;
			}
			.result,.game{
				height:155px;
				margin:0 auto;
				@extend %flexAlignCenter;
			}
			.game{
				@extend %flexAllCenter;
				flex-direction: column;
				font-size:12px;

				h4{
					margin-bottom:0.2rem;
					font-weight: bold;
					color:$color;
				}
				p{
					color: $color;
					margin-bottom:10px;
				}
				img{
					width:100px;
					max-height:120px;
				}
				button{
					font-size:12px;
					margin-top:10px;
					padding:3px 10px;
				}
				&.has::before{
					content: "";
					position: absolute;
					margin-top: 10px;
					right: calc(50% - 15px);
					background-image: url(#{$images}/arrow.png);
					@include wh(25);
					background-size: contain;
					background-repeat: no-repeat;
					@include rtlSameAttr(transform,scaleX(-1),scaleX(1));
				}
				
			}
		} 
		
	}

}
.charts,.admin{
	display: flex;
	flex-direction: column;

	.main{
		@extend %contentBox;
	}
	.btns{
		@extend %flexAlignCenter;
		margin-bottom:10px;

		button{
			margin:0 10px;
			border-radius: 15px;
			padding:5px 17px;
			color:$linkColor;
			background-color: $color;

			&.active{
				background-color: $titleRed;
			}
		}
	}
}



/* checkout */
.checkout{
	
	.top-area-with-btn{
		align-items: center;
		.back-btn{
			@include wh(30);
		}
		.texts{
			padding-top:0;
			h4{
				font-size:18px;
			}
		}
	}
	.payment-bottom,.payment-form{
		width:95%;
		margin:20px auto 0;
	}
	.payment-form{
		&>div{
			display: flex;
			margin-bottom:20px;
			order: 1;
		}
		label{
			width:30%;
			font-size:14px;
			min-width:30%;
		}
		.exp{
			display: flex;
			width:100%;
			justify-content: space-between;

			select{
				width:40%;
			}
		}
		.cvv{
			
			input{
			 width:60px;
		 }
		}
		.wrap-div-sp{
			&>div{
				display: flex;
				flex-direction: column;
				width:100%;
				p.error{
					margin:0;

				}
			}
		}
		
		.payment-cont{
			flex-direction: column;
			order:2;
		}
	}
	.coupon-cont{
		label{
			cursor: pointer;
		}
		.coupon{
			&>div{
				@extend %flexAlignCenter;
				direction: ltr;
				.hyphen{
					margin:0 10px;
				}
				input{
					width:56px;
					padding:4px;
				}
			}
			button{
				margin:10px 0;
				width:100%;
				
			}
	
		}
	}
	
	.divider{
		margin-top:20px;
		// padding-bottom:20px;
		border-top: 1px #ddd solid;
		height:0;
	}
	
	div>h2{
		color:#599f78;
		font-weight: bold;
	}
	.payment-bottom{
		text-align: center;
		
		.bottom-text{
			@include rtlSameAttr(text-align,left,right);
			margin:4px 0;
		}
		.img-div{
			@extend %flexAllCenter;
			img{
				max-height: 30px;
				margin:10px 10px;
			}
		}
		h2{
			font-size:20px;
			margin-bottom: 0;;
		}

		button{
			@extend %btnMargin;
			background:#599f78;
			font-weight: bold;
			width:100%;
			font-size: 20px;

			&:hover{
				background: transparent;
				border:1px #599f78 solid;
			}
		}
	
	}
}

.error-bounrady{
	@extend %flexAlignCenter;
	flex-direction: column;

	h1{
		margin:40px auto;
	}

}


// misc
.error{
  border-color:red;
}

/* The snackbar - position it at the bottom and in the middle of the screen */
.toast {
  opacity: 1;
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  /* margin-left: -125px; Divide value of min-width by 2 */
  background-color:#00aa00; /* Black background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1000000000; /* Add a z-index if needed */
  right: 40px; /* Center the snackbar */
  top: 20px; /* 30px from the bottom */
  
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
.toast.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
/* Animations to fade the snackbar in and out */
@keyframes fadein {
	from {top: 0; opacity: 0;}
	to { }
}
@keyframes fadeout {
  from { }
  to {top: 0; opacity: 0;}
}

/* icon select kid pics */

.icon_select_box{ 
	width:46px;
	height:30px; 
	background-color:#d0d0d0; 
	border-radius:4px; 
}
.icon_select_img{ 
	display:inline-block; 
	height:30px; width:30px;
	background-repeat:no-repeat; 
	background-image:url(#{$images}/system_faces.png); 
}
.icon_select_btn_box{ 
	display:inline-block; 
	height:100%; 
	border-left:solid 1px #bbb;

	&:lang(he),&:lang(ar){
		border-left:none; 
		border-right:solid 1px #bbb;
	}
}

.icon_select_btn{ 
	height:14px; 
	width:15px; 
	cursor:pointer; 
	background-repeat:no-repeat; 
	background-image:url(#{$images}/open-arrows.png);
}
.icon_select_btn_up{ 
	border-bottom:solid 1px #bbb; 
}
.icon_select_btn_down{ 
	background-position:0 -14px; 
	border-top:solid 1px #ddd;
}

/* circle graph */

.circChart{
	@extend %flexAllCenter;

	div{
		position: absolute;
		@include wh(110);
		@extend %circ;
		background: white;
		margin:10px;
		@extend %flexAllCenter;
		flex-direction: column;
		z-index: 1;
		padding:20px; 
		p{
			margin-bottom: 0;
			font-weight: bold;
			font-size:12px;

			&:first-child{
				margin-bottom:30px;
			}
			&:nth-child(2){
				color:$success !important;
			}
			&:nth-child(3){
				color:$error !important;
			}
		}
	} 
	svg{
		@include wh(133);
		transform: rotate(-90deg);
		background: $error;
		@extend %circ;
		fill:none;
	}
	circle {
		fill: $success;
		stroke: $success;
		stroke-width: 10;
		fill:none;
		/* stroke-dasharray: 89 100;  */
		animation: fillup 0.8s ease-in-out;
	}	
}


@keyframes fillup {
  from{stroke-dasharray: 0 100;}
}
.react-datepicker-wrapper{
  display: block !important;
}







/* Lang changes */

[dir=rtl] .menu{
	// margin-right:-8px;
	// margin-left:0;
}

// 1020