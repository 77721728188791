/* Desktop */
@media (min-width:1190px){
	section.pad{
		@include genPad(10);
	}
	.non-lg{
		display:none;
	}
	.only-lg{
		display:block;
	}
	.non-app{
		display: block;
	}
	.only-app{
		display:none;
	}

	header{
		padding: 5px 40px;
		.menu{
			&.user-links{
				span,a{
					font-size:inherit;
				}
			}
			&>ul>li{
				span::after,a::after{
					height: 15px;
				}
			}
			&.lang{
				.dropdown{
					left:auto;
				}
			}
		}
	}
	header>div,[dir=rtl] .menu,.menu{
		margin-left:12px;
		margin-right:12px;
	}
	.home-bg{
		background-size: auto calc(100vh - #{$headerH} - #{$topNotifH});
		height: calc(100vh - #{$headerH} - #{$topNotifH});
	}

	/* home */
	.home{
		.content{
			height: calc(100vh - #{$headerH} - #{$topNotifH});
			// padding-top: 40px;
			padding-top:0;
			justify-content: space-evenly;

			img{
				display: block;

				&.sitting-jumbee{
					display:none;
				}
				&.keyboard{
					max-width: 25%;
				}
			}
			h2{
				// text-align: center;
				display: block;
				margin:0;
			}
			.btns{
				margin-top:40px;
				margin-bottom:-20px;
				flex-direction: row;
				
				button{
					margin:0 10px;
				}
			}
		}
	} 
	/* static */
	.static .content{
		max-width: 80%;
	}

	/* filter */
	.filter .close{
		top: 140px;
		left: 24%;
		right: inherit;
		bottom: inherit;
	}

	/* modal-form */
	.modal-form{
		.bg-blue{
			padding-top:80px;
			height:calc(100vh - #{$headerH});
		}
		.modal{
			width:40%;
			.content {
				min-height:300px;
				display:flex;
				flex-direction: column;
				justify-content: space-between;

				.form{
					margin-top:40px;
					// margin-bottom:auto;
	
					li{
						// margin-bottom:20px;
					}
				}
				.regi-btns{
					margin-top:auto;
				}
			}
		}
		.close{
			margin-right:-43%;
		}
	} 
	
	/* store */
	.footer{
		width: 100%;
    left: 0;
		bottom: 0;
		padding: 12px 0;
    border-radius: inherit;
	}
	.store{
		&.pad{
			@include genPad(20);
			padding-bottom:60px;
		}
		.filter-icon{
			right: 15%;
			bottom: 40px;
		}
	}
	.product{
		.info{
			.btns{
				button{
					padding:3px 50px;
				}
			}
		}
	}
	 

	/* product-show */
	.product-show{
		&.pad{
			@include genPad(20);
		}
		.game-details .top-wrap{
			width:60%;
			.top-area-with-btn .texts>.stars-cont{
				margin-top:20px;
				flex-direction: row;
			}
		}
		
	} 
	.cart{
		.top-wrap{
			@include rtl(margin-left,margin-right,-50px);
		}
		&.pad{
			@include genPad(20);
			margin-bottom: 80px;
		}
		.product{
			.info{
				.btns{
					.empty{
						margin:0 20px;
					}
				}
			}
		}
		.above-footer{
			// @include genPad(20);
			padding:10px 20%;
		}
	}
	
	/* charts */
	.charts{
		flex-direction: column;
		&.pad{
			@include genPad(20);
		}
		.btns{
			min-width:auto;
			margin-bottom:0px;
			button{
				font-size:$fontSize;
				padding:5px 24px;
				border-radius: 15px;
			}
		}
		.main{
			padding: 40px;
			padding-top:0;

			

			p{
				font-size:$fontSize;
			}
			.stats{
				width:80%;
				margin:40px auto;

				h4{
					font-size:18px;
				}

				.result,.game{
					height:170px;
					margin:20px auto;
				}
				.game{

					p,h4,button{
						font-size:14px;
					}
					h4{
						margin-bottom:0.2rem;
					}
					img{
						max-height:80px;
						width:auto;
					}
					button{
						margin-top:10px;
						padding:3px 30px;
					}
					&.has::before{
						@include wh(30);
						right: calc(50% - 15px);
					}
				}
			}
		}

		.btns{
			flex-direction:row;

			button{
				margin-top:auto;

				&.filter{
					@include rtl(margin-left,margin-right,auto !important,0 !important);
				}
			}
		}
	}

	.checkout{
		&.pad{
			@include genPad(30);
		}
		.top-wrap{
			margin:0 -46px 0;
		}
		.payment-bottom,.payment-form{
			// width:50%;
			margin:20px auto;
		}
		.payment-form{
			display: flex;
			flex-direction: row;
			max-height:inherit;
			// height:auto;
			margin-bottom:10px;

			.wrap-div-sp{
				&>div{
					p.error{
						position:inherit;
						margin-top:0;
					}
				}
			}

			&>div{
				width:100%;
				label{
					min-width:30%;
				}
			}
			.exp-cont{
				@include rtlSameAttr(margin,0 0 14px,0 0 14px);

				order:1;
				.exp{
					select{
						width:40%;
						
					}
				}
			}
			.coupon-cont{
				align-items: flex-start;
				min-height:auto;
				margin-bottom:14px;
				.coupon{
					width:100%;
					&>div{
						justify-content: space-between;
					}
				}
				
			}
			.payment-cont{
				margin-top:auto;
				@include rtl(margin-left,margin-right,0);
				
				h2{
					font-size:24px;
				}
				button{
					width:100%;
					// border-radius: 20px;

					padding:10px 0;
					font-size: 18px;
				}
			}
		}
		.divider{
			margin-top:10px;
			padding-bottom: 10px;
		}
		.payment-bottom{
			display: block;
			font-size:14px;
			margin-top:0;
			.bottom-text{
				// margin:10px -40% 0;
			}
			.img-div{
				img{
					margin:0 10px;
				}
			}
		}
	}

	.circChart{
		div{
			@include wh(150);
			padding:20px;

			p{
				font-size:$fontSize;
			}
		}
		svg{
			@include wh(180);
		}
	}
}
