/* ipad */
@media (min-width:595px){
	button{
		&.link{
			&.checked{
				background-size: 13px;
			}
		}
	}

	header{
		height:$headerH;

		&>div{
			order:1 !important;
		}
		.menu{
			
			&.lang{
				button{
					padding:0;
				}
				
			} 
			
		}	
	}
	.non-app{
		display: none;
	}
	.non-xs{
		display: block;
	}
	.only-app{
		display:block;
	}
	[dir=rtl] .menu,.menu{
		// margin-left:0;
		// margin-right:0;
	}
	.dropdown{
		top:$headerH;		
	}

	/* home */
	.home-bg{
		background-size: auto calc(100vh - #{$headerH});
		height: calc(100vh - #{$headerH});
	}
	.home{
		.content{
			padding-top: 140px;


			img{
				display: none;

				&.sitting-jumbee{
					display: block;
					position: relative;
					max-width: 110px;
					margin-top: -100px;
					margin-bottom: -40px;
					margin-left: 90px;
				}
			}
			h2{
				display: none;
			}
		}
	}
	
	/* filter */
	.bg-blue{
		height: calc(100vh - 61px);
	}
	.filter{
		.bg-blue{
			p{
				font-size: 20px;
				margin-top: 20px;
				margin-bottom: 10px;
			}
		}
		.close{
			left:inherit;
			top:inherit;
			right:10%;
			bottom:10%;
		}
	}
	

	/* modal-form */
	.modal-form{
		.bg-blue{
			padding-top:40px;
			height:90vh;
		}
		.modal{
			width:70%;
		}
		.close{
			margin-right:-75%;
			margin-top:-32px;
		}
	}
	.regi-btns{
		margin-top:10px;
	}
	
	/* store */
	.footer{
		width: inherit;
    left: 9%;
    bottom: 2%;
    padding: 15px 20px 8px;
    border-radius: 30px;
	}
	.store{
		&.pad{
			@include genPad(1);
			padding-bottom:60px;
		}
		.filter-icon{
			right:20px;
			bottom:20px;
		}
	}
	
	.product{
		margin:0 auto 15px;
		img{
			max-height:130px;
			max-width: 100%;
		}
		.info{
			padding:12px;

			h5{
				font-size:18px;
			}
			p{
				font-size: 16px;
			}
			.btns{
				display: flex;
				flex-direction: row;
				min-width:150px;
				// justify-content: space-between;

				.old-price{
					font-size:20px;
				}
				
				button{
					height:34px;
					font-size: 18px;
					padding:3px 28px;
					margin:inherit;
				}
			}
		}
	}

	.ages{
		font-size: 12px;
		margin-top: 20px;
		padding:4px 10px;
	}

	ul.form{
		li{
			&.kid-row{
				flex-wrap: nowrap;
				&>div{
					width:auto;
					&:first-child{
						margin-bottom: 0;;
					}
				}

			}
		}
	}
	
	/* product show */
	
	.product-show{
		&.pad{
			@include genPad(2);
		}

		.game-details{
			flex-direction: row;

			.product-pic{
				img{
					min-width:100%;
				}
				button{
					margin-top:8px;
					padding:10px;
				}
			}

			&>.stars-cont{
				display:none;
			}
			.top-wrap{
				width:40%;
				.top-area-with-btn{
					p{
						min-width: auto;;
					}
					.texts>.stars-cont{
						display:flex;
						flex-direction: column;
						img{
							@include wh(14);
						}
						span{
							margin:0 8px;
							font-size:14px;
						}
					}
				}
				
				
			}
			
		}
		.more-games{
			h3{
				font-size:18px;
			}
		}
		
	} 

	.cart{
		.top-wrap{
			.top-area-with-btn{
				.back-btn{
					@include wh(40);
				}
				.texts{
					h4{
						font-size:$fontSize;
					}
					p{
						font-size:16px;
					}
				}
			}
		}
		&.pad{
			padding-bottom:80px;
		}
		.info{
			.btns{
				.cart-price{
					font-size:24px;;
				}
			}
		}
		.product{
			img{
				max-height: 90px;
			}
		}
	}
	
	/* charts */
	.charts{
		flex-direction: row;

		.main{
			padding-top:0;
			.stats{
				.game{
					&.has::before{
						right:calc(50% + 90px);
					}
				}
			}
		}
		
		.btns{
			min-width:200px;
			flex-direction:column ;
			

			button{
				margin-top:10px;
				font-size:14px;
				padding:14px 26px;
				border-radius: 24px;
				margin-bottom:15px;


				&.filter{
					margin-left:inherit !important;
					margin-right:inherit !important;
				}
			}
		}
	}
	
	.checkout{
		&.pad{
			@include genPad(2);
		}
		.payment-bottom,.payment-form{
			width:100%;
			margin:0;
			margin-top:10px;
		}
		.payment-form{
			display: flex;
			flex-direction: column;
			max-height:214px;
			flex-wrap: wrap;

			.wrap-div-sp{
				&>div{
					p.error{
						position: absolute;
						margin-top:30px;
					}
				}
			}

			&>div{
				align-items: center;
				margin-bottom:14px;
				label{
					min-width:33%;
				}
			}
			.exp-cont{
				// margin:0 4px 14px;
				@include rtl(margin-left,margin-right,20px);
				order:2;
				.exp{
					select{
						width:45%;
						padding:.375rem .5rem;
					}
				}
			}
			.coupon-cont{
				align-items: flex-start;
				min-height:74px;
				margin-bottom:0;
				.coupon{
					width:100%;
					&>div{
						justify-content: space-between;
					}
				}
			}
			.payment-cont{
				@include rtl(margin-left,margin-right,20px);
				
				margin-top:auto;
				margin-bottom:12px;
				h2{
					font-size:24px;
				}
				button{
					width:100%;
					padding:8px 0;
					font-size: 22px;
				}
			}
		}
		.divider{
			margin-top:0;
		}
		.payment-bottom{
			margin-top:0px;
			display: flex;
			align-items: flex-start;
			font-size:14px;

			.img-div{
				img{
					margin:0 10px;
				}
			}
		}
	}

	.circChart{
		div{
			
		}
		svg{
			@include wh(140);
		}
	}
	
}
